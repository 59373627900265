import { IApiBanner } from "@/types/interfaces/banners";

import styles from "./styles.module.scss";
import Image from "@/components/basic/Image";
interface CardSale extends IApiBanner {
  onClick: (item: CardSale) => void;
  isMobileSize: boolean;
}

const CardSale = (props: CardSale) => {
  const { alt_text, desktop_image, mobile_image, onClick, isMobileSize } = props;

  return (
    <div className={styles.CardSale} onClick={() => onClick(props)}>
      <Image
        src={(isMobileSize ? mobile_image || desktop_image : desktop_image || mobile_image) || ""}
        width={341}
        height={216}
        quality={85}
        alt={alt_text}
        unoptimized
      />
    </div>
  );
};

export default CardSale;
