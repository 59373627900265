import {getRecentlyViewedProductIds} from '@/utils/recentlyViewed';
import {useEffect, useMemo, useState} from 'react';
import {IApiProductDetails} from '@/types/interfaces/products';

import publicRequests from '@/modules/public/requests';

export const useRecentlyViewedProducts = (locale: string) => {
  const [products, setProducts] = useState<IApiProductDetails[] | null>(null);

  useEffect(() => {
    const productIds: string[] = getRecentlyViewedProductIds();
    publicRequests.getProductsByIds(locale, {
      ids: productIds,
    }).then((data) => {
      setProducts(data);
    })
  }, [locale]);

  return useMemo(() => ({
    products: products
  }), [products]);
}





