export enum PUBLIC_BANNER_TYPE {
  TOP = "TOP",
  MIDDLE = "MIDDLE",
}

export interface IApiBanner {
  id: number;
  name: string;
  link: string;
  banner_type: PUBLIC_BANNER_TYPE;
  desktop_image: string | null;
  mobile_image: string | null;
  alt_text: string;
  ordering: number;
  is_active: boolean;
}
