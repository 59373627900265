"use client";
import cn from '@/libs/cn';
import styles from '@/modules/home/components/SlideProducts.module.scss';
import CardProduct from '@/modules/home/components/CardProduct';
import Slide from '@/components/Slide';
import {useRecentlyViewedProducts} from '@/modules/public/hooks/useRecentlyViewedProducts/useRecentlyViewedProducts';

interface SlideRecentlyViewedProps {
  locale: string;
  title?: string;
  isMobileSize: boolean;
  className?: string;
  classNameSlide?: string;
}

const MIN_PRODUCTS = 2;

const SlideRecentlyViewed = (props: SlideRecentlyViewedProps) => {
  const { locale, title, className, classNameSlide, isMobileSize } = props;
  const recentlyViewedData = useRecentlyViewedProducts(locale);
  const products = recentlyViewedData?.products || [];
  if(products.length <= MIN_PRODUCTS) {
    return null;
  }

  return (
    <div className={cn(styles.SlideProducts, className)}>
      {!!title && (
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <div />
        </div>
      )}
      <div className={classNameSlide}>
        {isMobileSize ? (
          <div className={styles.mobileList}>
            {products.slice(0, 4).map((product: any, idx: number) => (
              <div key={product.id} className={styles.slideItem}>
                <CardProduct
                  small
                  {...product}
                  priority={isMobileSize ? idx < 2 : idx < 5}
                  imageLoading='eager'
                />
              </div>
            ))}
          </div>
        ) : (
          <Slide
            classNameItem={styles.slideItem}
            autoMargin={false}
            marginItem={0}
            gap={isMobileSize ? 12 : 20}
            offsetOutside={false}
            arrowsOffsetX={10}
            arrowsOffsetY={-48}
            hideWrapBorder
            hideArrows={isMobileSize}
            hideOnHover={false}
            highlightItemOnHover={false}
          >
            {products.map((product: any, idx: number) => (
              <CardProduct
                key={product.id}
                small
                {...product}
                priority={isMobileSize ? idx < 2 : idx < 5}
                imageLoading='eager'
              />
            ))}
          </Slide>
        )}
      </div>
    </div>
  );
}

export default SlideRecentlyViewed;
