"use client";
import cn from "@/libs/cn";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InlineIcons from "../basic/InlineIcons";

import styles from "./styles.module.scss";
import PublicIcons from "../basic/PublicIcon";

const isSSR = typeof window === "undefined";

interface Slide {
  children: JSX.Element[];
  height?: any;
  onSelect?: (idx: number) => void;
  className?: string;
  classNameItem?: string;
  autoMargin?: boolean;
  gap?: number;
  hideWrapBorder?: boolean;
  hideArrows?: boolean;
  offsetOutside?: boolean;
  arrowsOffsetX?: number;
  arrowsOffsetY?: number;
  marginItem?: any;
  hideOnHover?: boolean;
  highlightItemOnHover?: boolean;
}

const Slide = ({
  children = [],
  height,
  onSelect = () => {},
  className,
  classNameItem = "",
  autoMargin,
  gap = 20,
  hideWrapBorder = false,
  hideArrows,
  offsetOutside = true,
  arrowsOffsetX = 0,
  arrowsOffsetY = 0,
  marginItem = "10px",
  hideOnHover = true,
                 highlightItemOnHover = true,
}: Slide) => {
  const refList = useRef(null);
  const [widthSlide, setWidthSlide] = useState(null);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(0);
  const [widthChild, setWidthChild] = useState(100);

  const setInitValues = useCallback(
    (el: any) => {
      if (children.length) {
        const rect = el.children[0].getBoundingClientRect();
        setWidthChild(rect.width);
      }
    },
    [children.length]
  );

  useEffect(() => {
    if (refList.current && widthSlide) {
      setInitValues(refList.current);
    }
  }, [setInitValues, widthSlide, children]);

  const resizeObserver = useMemo(() => {
    if (isSSR) {
      return;
    }

    return new ResizeObserver((entries) => {
      if (refList?.current) {
        // @ts-ignore
        const rect = refList.current.getBoundingClientRect();
        setTimeout(() => setWidthSlide(rect.width), 0);
      }
    });
  }, []);

  useEffect(() => {
    if (refList?.current) {
      if (!isSSR) {
        // @ts-ignore
        resizeObserver.observe(document.scrollingElement);
      }
    }
  }, [resizeObserver]);

  const onLeft = useCallback(() => {
    // @ts-ignore
    refList.current.scrollLeft -= (widthChild + gap) * 2;
  }, [gap, widthChild]);

  const onRight = useCallback(() => {
    // @ts-ignore
    refList.current.scrollLeft += (widthChild + gap) * 2;
  }, [gap, widthChild]);

  return (
    <div
      className={cn(styles.Slide, autoMargin && styles.autoMargin, className)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ height }}
    >
      <div ref={refList} className={styles.list} style={{ gap }}>
        {children.map((item, idx) => (
          <div
            key={idx}
            className={cn(styles.wrapItem, classNameItem, active === idx && styles.active, highlightItemOnHover && styles.highlightOnHover)}
            style={{
              borderWidth: hideWrapBorder ? 0 : undefined,
              marginLeft: idx === 0 ? 0 : marginItem,
              marginRight: children.length - 1 === idx ? 0 : marginItem,
            }}
            onClick={() => {
              setActive(idx);
              onSelect(idx);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      {!hideArrows && (
        <div
          className={cn(styles.arrowLeft, offsetOutside && styles.offsetOutside, (!hideOnHover || hideOnHover && hover) && styles.show)}
          style={{ marginTop: arrowsOffsetY, marginLeft: arrowsOffsetX }}
          onClick={onLeft}
        >
          <PublicIcons name="arrowShortLeftGrey" width={24} height={24} />
        </div>
      )}
      {!hideArrows && (
        <div
          className={cn(styles.arrowRight, offsetOutside && styles.offsetOutside, (!hideOnHover || hideOnHover && hover) && styles.show)}
          style={{ marginTop: arrowsOffsetY, marginRight: arrowsOffsetX }}
          onClick={onRight}
        >
          <PublicIcons name="arrowShortRightGrey" width={24} height={24} />
        </div>
      )}
    </div>
  );
};

export default Slide;
