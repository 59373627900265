"use client";
import { useCallback, useEffect } from "react";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import useClientRouter from "@/hooks/useClientRouter";
import useLazyLoadListWithFilter from "@/hooks/useLazyLoadListWithFilter";
import { IApiProductOfList } from "@/types/interfaces/products";
import apiPublic from "@/modules/public/constants/apiPublic";
import routesPublic from "@/modules/public/constants/routesPublic";
import ButtonShowMore from "@/modules/public/components/ButtonShowMore";
import useDetectUserInteraction from "@/hooks/useDetectUserInteraction";
import CardProduct from "../CardProduct";

import styles from "./styles.module.scss";

interface MoreProductsListProps {
  lazy: boolean;
}

const MoreProductsList = (props: MoreProductsListProps) => {
  const { lazy } = props;
  const { locale, translate, currency, toast, providers, isMobileSize } = useClientContext();
  const router = useClientRouter();
  const interaction = useDetectUserInteraction();

  //   const { sortby, search, ...restQuery } = initQuery;
  const pageSize = isMobileSize ? 30 : 60;

  const onChangeUrl = useCallback(
    (nextRoute: string) => {
      router.replace(nextRoute, { shallow: false, scroll: true });
    },
    [router]
  );

  const { list, onNextPage, loading, dirty, ended } = useLazyLoadListWithFilter<IApiProductOfList>({
    key: router.asPath,
    provider: providers.common,
    initQuery: {},
    apiQueryParams: {},
    routeQueryParams: {},
    initList: [],
    transformResponse: (response) => response || [],
    url: apiPublic.productsMoreToLove(locale, `page_size=${pageSize}`),
    initRequest: false,
    nextPage: apiPublic.productsMoreToLove(locale, `page_size=${pageSize}&offset=0`),
    onError: toast.error,
    route: `${routesPublic.category()}`,
    onChangeUrl,
  });

  useEffect(() => {
    onNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (lazy && !interaction) {
    return null;
  }

  return (
    <div className={styles.MoreProductsList}>
      <h2 className={styles.title}>{translate("More to love")}</h2>
      <div className={styles.list}>
        {list.map((item) => (
          <div key={item.id} className={cn(styles.wrapItem, dirty && styles.dirty)}>
            <CardProduct key={item.id} {...item} />
          </div>
        ))}
      </div>
      {!ended && <ButtonShowMore onClick={() => onNextPage()} loading={loading} />}
    </div>
  );
};

export default MoreProductsList;
