"use client";
import { useCallback } from "react";
import useClientRouter from "@/hooks/useClientRouter";
import useClientContext from "@/context/ClientContext";
import { useDataPublicBanners } from "@/modules/public/hooks/useData";
import { PUBLIC_BANNER_TYPE } from "@/types/interfaces/banners";
import Slide from "@/components/Slide";
import useDetectUserInteraction from "@/hooks/useDetectUserInteraction";
import CardSale from "./CardSale";

import styles from "./SlideSales.module.scss";
import CustomCarouselSlick from '@/components/CustomCarouselSlick';

interface SlideSalesProps {
  title?: string;
  lazy: boolean;
}

const SlideSales = (props: SlideSalesProps) => {
  const { title, lazy } = props;
  const router = useClientRouter();
  const { isMobileSize } = useClientContext();
  const { data } = useDataPublicBanners();
  const interaction = useDetectUserInteraction();

  const list = data.filter((i) => i.banner_type === PUBLIC_BANNER_TYPE.MIDDLE);

  const onClick = useCallback(
    (item: CardSale) => {
      router.push(item.link);
    },
    [router]
  );

  if (lazy && !interaction) {
    return null;
  }

  return (
    <div className={styles.SlideSales}>
      {!!title && (
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
        </div>
      )}
      <div className={styles.wrap}>
        <CustomCarouselSlick
          className={styles.slide}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={3000}
          dots={false}
          variableWidth
        >
          {list?.map((item) => (
            <CardSale key={item.link} {...item} onClick={onClick} isMobileSize={isMobileSize} />
          ))}
        </CustomCarouselSlick>
      </div>
    </div>
  );
};

export default SlideSales;
