"use client";
import useClientContext from "@/context/ClientContext";
import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import cn from "@/libs/cn";
import routesPublic, { getVirtualCategoryURL } from "@/modules/public/constants/routesPublic";
import PublicIcons from "@/components/basic/PublicIcon";

import styles from "./styles.module.scss";

export const DATA_ID_TOP_CATEGORIES = "top-categories";

export interface ItemCategory {
  id: number;
  name: string;
  slug: string;
  icon: string | null;
  level: number | undefined;
  has_children: boolean;
  is_virtual: boolean;
  extra_filters: string | null;
}

interface TopCategoriesLayoutProps {
  data: ItemCategory[];
  className?: string;
  classNameItem?: string;
  activeSlug?: string;
  breakPaddingFirstItem?: boolean;
}

const TopCategoriesLayout = (props: TopCategoriesLayoutProps) => {
  const { data, className, classNameItem, activeSlug, breakPaddingFirstItem = false } = props;
  const { isTouchable } = useClientContext();

  return (
    <nav className={cn(styles.TopCategoriesLayout, className)} data-id={DATA_ID_TOP_CATEGORIES}>
      {data.map((item: ItemCategory) => {
        const { is_virtual, extra_filters, slug } = item;
        const isActive = activeSlug === item.slug;

        const href = is_virtual
          ? getVirtualCategoryURL([], extra_filters, [slug])
          : `${routesPublic.category()}${item.slug}/`;

        return (
          <Link
            data-id={item.id}
            data-stop={isTouchable}
            href={href}
            key={item.id}
            className={cn(
              styles.item,
              !breakPaddingFirstItem && styles.breakPaddingFirstItem,
              isActive && styles.active,
              classNameItem
            )}
          >
            <div className={styles.icon}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <Image src={item.icon || ""} width={20} height={20} />
            </div>
            <div className={styles.name}>{item.name}</div>
            <div style={{ flex: 1 }} />
            {item.has_children && <PublicIcons name="arrowRight20" width={20} height={20} />}
          </Link>
        );
      })}
    </nav>
  );
};

export default TopCategoriesLayout;
