"use client";
import useClientContext from "@/context/ClientContext";
import cn from "@/libs/cn";
import {useCallback, useEffect, useRef, useState} from "react";

import styles from "./styles.module.scss";

interface TopCategoriesClientWrapProps {
  children: any;
  onHover: (categoryId: string) => void;
  minHeight?: number;
  className?: string;
}

const TopCategoriesClientWrap = (props: TopCategoriesClientWrapProps) => {
  const { children, className, onHover: onHoverOut, minHeight } = props;
  const { isTouchable } = useClientContext();
  const ref = useRef(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();

  const clearTimer = useCallback(() => {
    if(timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }
  }, [timer]);

  const onHover = useCallback(
    (ev: any) => {
      clearTimer();
      setTimer(setTimeout(() => {
        const id = ev.target?.dataset?.id;
        if (id) {
          onHoverOut(id);
        }
      }, 100));
    },
    [clearTimer, onHoverOut]
  );

  const onTouchableClick = useCallback(
    (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();

      const id = ev.target?.dataset?.id;
      if (id) {
        onHoverOut(id);
      }
    },
    [onHoverOut]
  );

  useEffect(() => {
    // @ts-ignore
    ref.current?.addEventListener("mouseover", onHover);
    // @ts-ignore
    ref.current?.addEventListener("mouseout", clearTimer);
    return () => {
      // @ts-ignore
      ref.current?.removeEventListener("mouseover", onHover);
      // @ts-ignore
      ref.current?.removeEventListener("mouseout", clearTimer);
    };
  }, [clearTimer, onHover]);

  useEffect(() => {
    if (!isTouchable) {
      return;
    }
    // @ts-ignore
    ref.current?.addEventListener("click", onTouchableClick);
    // @ts-ignore
    return () => ref.current?.removeEventListener("click", onTouchableClick);
  }, [isTouchable, onTouchableClick]);

  return (
    <div ref={ref} className={cn(styles.TopCategoriesClientWrap, className)} style={{ minHeight }}>
      {children}
    </div>
  );
};

export default TopCategoriesClientWrap;
