"use client";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import cn from "@/libs/cn";
import SubCategoriesList from "@/modules/layout/components/PopupCategories/SubCategoryList";
import { useDataPublicCategories } from "@/modules/public/hooks/useData";
import TopCategoriesClientWrap from "../TopCategoriesLayout/TopCategoriesClientWrap";
import { DATA_ID_TOP_CATEGORIES } from "../TopCategoriesLayout";

import styles from "./styles.module.scss";
import {useListSidebarPosition} from '@/hooks/useListSidebarPosition';

const VERTICAL_MARGIN = 20;

interface LeftsideCategories {
  children: any;
  stickySublistPosition: boolean;
}

const LeftsideCategories = (props: LeftsideCategories) => {
  const { children, stickySublistPosition } = props;
  const [topCategory, setTopCategory] = useState<string | null>(null);

  const sublistRef = useRef<HTMLDivElement | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const categories = useDataPublicCategories(!topCategory);

  const changeSublistTopPosition = useCallback(() => {
    if (!stickySublistPosition) {
      return;
    }
    const sublist = sublistRef.current;
    const clientRect = sublist?.getBoundingClientRect();

    const parent = parentRef.current;
    const parentRect = parent?.getBoundingClientRect();

    if (sublist) {
      if (clientRect && parentRect && parentRect.top <= 0) {
        sublist.style.top = Math.abs(parentRect.top) + "px";
      } else {
        sublist.style.top = "0px";
      }
    }
  }, [stickySublistPosition]);

  const closeSublist = useCallback(() => {
    setTopCategory(null);
  }, []);

  useLayoutEffect(() => {
    if (topCategory) {
      changeSublistTopPosition();
    }
  }, [topCategory, changeSublistTopPosition]);

  useEffect(() => {
    document?.addEventListener("scroll", changeSublistTopPosition, false);
    return () => document?.removeEventListener("scroll", changeSublistTopPosition, false);
  }, [changeSublistTopPosition]);

  const existChildren = useMemo(
    () => topCategory && categories.data.some((subItem) => `${subItem.parent}` === `${topCategory}`),
    [categories.data, topCategory]
  );

  const active = !!existChildren;

  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const parent = parentRef.current;
    const container = parent?.querySelector(`[data-id="${DATA_ID_TOP_CATEGORIES}"]`);
    if (!parent || !container) {
      return;
    }

    const rect = container.getBoundingClientRect();
    setHeight(rect.height);
  }, []);

  useEffect(() => {
    const parent = parentRef.current;
    const activeClassName = styles.topCategoryActive;

    if (topCategory) {
      const link = parent?.querySelector(`a[data-id="${topCategory}"]`);
      link?.classList.add(activeClassName);
    }

    return () => {
      const links = parent?.querySelectorAll(`a[data-id]`);
      links?.forEach((i) => i.classList.remove(activeClassName));
    };
  }, [topCategory]);

  const {stickTo} = useListSidebarPosition(parentRef);

  return (
    <div
      className={cn(
        styles.leftSide,
        stickTo === 'top' && styles.stickToTop,
        stickTo === 'top-relative' && styles.stickToTopRelative,
        stickTo === 'bottom' && styles.stickToBottom,
        active && styles.active
      )
      }
       ref={parentRef}
    >
      <div className={cn(styles.backdoor, active && styles.show)} />
      <div onMouseLeave={closeSublist}>
        <TopCategoriesClientWrap
          onHover={setTopCategory}
          className={cn(active && styles.wrapActive)}
          minHeight={height}
        >
          <>
            <div
              className={cn(styles.background, active && styles.show)}
              ref={ref}
              style={{ minHeight: height + VERTICAL_MARGIN * 2 }}
            />
            {children}
          </>
        </TopCategoriesClientWrap>
        {existChildren && (
          <div className={styles.sub} ref={sublistRef} style={{ minHeight: height + VERTICAL_MARGIN * 2 }}>
            <SubCategoriesList parentCategoryId={topCategory} data={categories.data} onHide={closeSublist} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftsideCategories;
