import React from "react";
import cn from "@/libs/cn";
import { IApiCategoryOfList } from "@/types/interfaces/categories";
import routesPublic, { getVirtualCategoryURL } from "@/modules/public/constants/routesPublic";
import PublicIcons from "@/components/basic/PublicIcon";
import Link from "@/components/basic/Link";

import styles from "./styles.module.scss";

interface SubCategoryItem {
  slugs: string[];
  name: string;
  level: number;
  has_children: boolean;
  is_virtual: boolean;
  extra_filters: string | null;
  onClick?: (e?: any) => void;
}

const SubCategoryItem = (props: SubCategoryItem) => {
  const { slugs, name, level, has_children, is_virtual, extra_filters, onClick } = props;
  // TODO category level
  if (level > 2) {
    return null;
  }

  const href = is_virtual
    ? getVirtualCategoryURL(slugs, extra_filters, slugs)
    : `${routesPublic.category()}${slugs.join("/")}/`;

  return (
    <Link
      href={href}
      className={cn(level === 1 && styles.levelOne, level === 2 && styles.levelTwo)}
      onClick={onClick}
      title={name}
    >
      <div className={styles.name}>{name}</div>
      <div style={{ flex: 1 }} />
      {level == 2 && has_children && <PublicIcons name="arrowRight20" width={20} height={20} />}
    </Link>
  );
};

interface SubCategoriesList {
  parentCategoryId?: number | string | null;
  level?: number;
  data: IApiCategoryOfList[];
  onHide?: (e?: any) => void;
}

const SubCategoriesList = ({ data, parentCategoryId, onHide }: SubCategoriesList) => {
  if (parentCategoryId === undefined) {
    return null;
  }

  return (
    <div className={cn(styles.subCategoriesList)}>
      {data.map((item) => {
        if (`${item.parent}` !== `${parentCategoryId}`) {
          return null;
        }

        const slugs = getBreadcrumbs(item, data).map((i) => i.slug);

        return (
          <div key={item.slug} className={styles.tiles}>
            <SubCategoryItem {...item} slugs={slugs} onClick={onHide} />
            <SubCategoriesList parentCategoryId={item.id} data={data} level={item.level} onHide={onHide} />
          </div>
        );
      })}
    </div>
  );
};

export default SubCategoriesList;

const getBreadcrumbs = (item: IApiCategoryOfList, data: IApiCategoryOfList[]) => {
  const arr = [item];

  let p = item;
  while (p.level) {
    const parent = data.find((i) => i.id === p.parent);
    if (parent) {
      arr.push(parent);
      p = parent;
    } else {
      break;
    }
  }

  return arr.reverse();
};
